/* Modals */
.modal-btn-close-video,
.modal-btn-close {
	background-image: url(../assets/close.svg);
	background-repeat: no-repeat;
}
.modal-btn-close-video {
	margin-right: -7px !important;
}

.modal-btn-close-video,
.modal-btn-close:hover {
	background-image: url(../assets/close.svg);
	background-repeat: no-repeat;
}

.modal-btn-close-video,
.modal-btn-close:focus {
}

/* PDF modal */
.modal-pdf-container {
	width: 80% !important;
	height: 85vh !important;
	padding-top: 2em !important;
	border-radius: 0px !important;
	overflow: hidden !important;
}

.modal-pdf-html {
	padding-top: 1em !important;
	margin: 0 !important;
}

/* Video modal */

.modal-video-container {
	padding-top: 1em !important;
	width: 90% !important;
	border-radius: 0px !important;
}

.modal-video-html {
	padding-top: 2em !important;
	padding-left: 1em !important;
	padding-right: 1em !important;
	margin: 0 !important;
}

.modal-papel-container {
	width: fit-content !important;
	height: fit-content;
	padding: 2rem !important;
	border-radius: 0 !important;
}

/* Text modal */
.modal-text-container {
	width: 90% !important;
	height: 90%;
	max-height: 700px;
	padding-top: 2em !important;
	padding-bottom: 4em !important;
	border-radius: 0 !important;
}

.modal-text-title {
	padding: 0 0 0.5em !important;
	text-align: center !important;
	color: rgba(27, 58, 148, 1);
	font-size: 22px !important;
	letter-spacing: 1px !important;
}

.modal-text-html {
	padding: 0.5em 3em 0.5em !important;
	margin: 0 !important;
	text-align: justify !important;
	line-height: inherit !important;
	color: black !important;
	font-size: 18px !important;
	letter-spacing: 0.8px !important;
	height: 100%;
}

.modal-wait-container {
	border-radius: 0px !important;
	height: 60%;
	width: 55% !important;
	padding: 2em 2.5em 2em 2em !important;
}

/* Confirm modal */

.modal-confirm-container {
	height: 85%;
	width: 55% !important;
	padding: 2em 2.5em 0 2em !important;
	border-radius: 0 !important;
}

.modal-confirm-icon {
	position: absolute;
	top: 3em;
	left: 50%;
	transform: translate(-50%);
}

.modal-confirm-title {
	color: #1b3a94 !important;
	font-size: 22px !important;
	font-weight: 700 !important;
	font-style: normal !important;
	letter-spacing: 0, 61px !important;
	padding: 0 !important;
	position: absolute !important;
	left: 0;
	right: 0;
	top: 7em;
	bottom: 6em;
}

/*Question explanation modal */
.modal-question-explanation {
	border-radius: 0px !important;
	opacity: 1;
	background-color: rgba(255, 255, 255, 1) !important;
	padding-top: 2rem !important;
	padding-bottom: 2rem !important;
	width: 60% !important;
	max-width: 780px !important;
	overflow-x: hidden !important;
	color: black;
}

.modal-sello-container {
	border-radius: 0px !important;
	opacity: 1;
	background-color: rgba(255, 255, 255, 1) !important;
	padding-top: 2em !important;
	width: 60% !important;
	height: 90% !important;
	max-height: 700px !important;
	color: black;
}

.modal-caso-clinico-resolution {
	border-radius: 0px !important;
	opacity: 1;
	background-color: rgba(255, 255, 255, 1) !important;
	padding-top: 2rem !important;
	padding-bottom: 2rem !important;
	width: 80% !important;
	overflow-x: hidden !important;
	color: black;
}

.modal-caso-clinico-html {
	padding: 0.5em 1.5em 0.5em !important;
	line-height: 16px !important;
	font-size: 18px !important;
	letter-spacing: 0px !important;
}

.modal-enunciado-container {
	width: 90% !important;
	height: 90%;
	max-height: 700px;
	padding-top: 2em !important;
	border-radius: 0 !important;
}

.modal-enunciado-html {
	padding: 0.5em 3em !important;
	margin: 0 !important;
	text-align: justify !important;
	line-height: inherit !important;
	color: black !important;
	font-size: 18px !important;
	letter-spacing: 0.8px !important;
	height: 100%;
}
